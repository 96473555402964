@import 'https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap';

@media (min-width: 1100px) {



  .ball {
    width: 10.3vh;
    height: 10.3vh;
    background:#ffff05;
    position: absolute;
    border-radius: 50%;
    animation: mymove1 5s infinite;
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5);
  }

  .ball:hover {
    animation: reverse;
  }
  
  .mySquare {
    width: 8.3vh;
    height: 8.3vh;
    background:#00c5e8;
    position: absolute;
    animation: mymove2 15s infinite;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }
  
  
  @keyframes mymove1 {
    0%   {top: 7.2vh; left: 25.8vh; opacity: 0.75;}
    25%  {top: 48.6vh; left: 25.8vh; border-radius: 0%; opacity: 1;}
    50%  {top: 89.5vh; left: 25.8vh; border-radius: 50%; opacity: 0.75;}
    75%  {top: 48.6vh; left: 25.8vh; border-radius: 0%; opacity: 1;}
    100% {top: 7.2vh; left: 25.8vh; opacity: 0.75;}
  }
  
  @keyframes mymove2 {
    0%   {top: 90vh; left: 93.4vh; transform: rotate(45deg); background-color: purple;}
    25%  {top: 43.9vh; left: 188.4vh; transform: rotate(135deg); background-color: yellow;}
    50%  {top: 8.8vh; left: 93.4vh; transform: rotate(225deg); background-color: green;}
    75%  {top: 43.9vh; left: 1vh; transform: rotate(135deg); background-color: red;}
    100% {top: 90vh; left: 93.4vh; transform: rotate(225deg);background-color: purple;}
  }

  


  .react-logo {
    position: absolute;
    width: 57.5vh;
    height: 23.5vh;  
    margin-top: 20vh;
    margin-left: 40vh;
  }
  .react-logo__circle {
    position: absolute;
    width: 10.32vh;
    height: 10.32vh;
    border-radius: 50%;
    background-color: #00c5e8;
    left: 21.7vh;
    top: 24.8vh;
  }

  .react-logo__ellipse {
    position: absolute;
    width: 53vh;
    height: 25vh;
    left: 0;
    top: 16.6vh;
   
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
  }

  .react-logo__ellipse_2 {
    position: absolute;
    width: 53vh;
    height: 25vh;
    left: 0;
    top: 16.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(60deg);
    animation: logomove1 5s infinite;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }

  .react-logo__ellipse_3 {
    position: absolute;
    width: 53vh;
    height: 25vh;
    left: 0;
    top: 16.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(120deg);
    animation: logomove3 5s infinite;
    animation-timing-function: cubic-bezier(0.75, 0.75, 0.25, 0.25);
  }



@keyframes logomove1 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(90deg);}
  50%  {transform: rotate(180deg);}
  75%  {transform: rotate(270deg);}
  100% {transform: rotate(360deg);}
  
}

@keyframes logomove2 {
  0%, 25%   {transform: rotate(0deg);}
  50%  {transform: rotate(120deg);}
  75%  {transform: rotate(240deg);}
  100% {transform: rotate(360deg);}
}

@keyframes logomove3 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(-90deg);}
  50%  {transform: rotate(-180deg);}
  75%  {transform: rotate(-270deg);}
  100% {transform: rotate(-360deg);}
}

.message {
  opacity: 0;
}



.P {
  position: absolute;
  top: 8vh;
  left: 76vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: P 15s infinite;
}

@keyframes P {
  0%  {top: 8vh; left: 76vh;}
  35% {top: 8vh; left: 76vh;}
  50% {top: 8vh; left: 60vh;}
  65% {top: 8vh; left: 60vh;}
  100% {top: 8vh; left: 76vh;}
}

.L {
  position: absolute;
  top: 8vh;
  left: 81vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: L 15s infinite;
  animation-timing-function: cubic-bezier(0.25, 0.75, 0.75, 0.25)
}

@keyframes L {
  0% {top: 8vh; left: 81vh; transform: rotate(0deg);}
  5% {transform: rotate(45deg);}
  10% {transform: rotate(0deg);}
  15% {transform: rotate(-45deg);}
  20% {transform: rotate(0deg);}
  25% {transform: rotate(45deg);}
  30% {transform: rotate(0deg);}
  35% {transform: rotate(-45deg);}
  40% {top: 8vh; left: 81vh; transform: rotate(0deg);}
  45% {top: 23vh; left: 81vh; transform: rotate(45deg);}
  50% {transform: rotate(0deg);}
  55% {transform: rotate(-45deg);}
  60% {transform: rotate(0deg);}
  65% {transform: rotate(45deg);}
  70% {top: 23vh; left: 81vh; transform: rotate(0deg);}
  75% {transform: rotate(-45deg);}
  80% {transform: rotate(0deg);}
  85% {transform: rotate(45deg);}
  90% {top: 8vh; left: 81vh; transform: rotate(0deg);}
  95% {transform: rotate(-45deg);}
  100% {top: 8vh; left: 81vh; transform: rotate(0deg);}
}

.A {
  position: absolute;
  top: 8vh;
  left: 84vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: A 15s infinite;
}

@keyframes A {
  0%  {top: 8vh; left: 84vh;}
  35% {top: 8vh; left: 84vh;}
  50% {top: 8vh; left: 68vh;}
  65% {top: 8vh; left: 68vh;}
  100% {top: 8vh; left: 84vh;}
}

.Y {
  position: absolute;
  top: 8vh;
  left: 89vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: Y 15s infinite;
}

@keyframes Y {
  0%  {top: 8vh; left: 89vh;}
  35% {top: 8vh; left: 89vh;}
  50% {top: 8vh; left: 73vh;}
  65% {top: 8vh; left: 73vh;}
  100% {top: 8vh; left: 89vh;}
}

.G {
  position: absolute;
  top: 8vh;
  left: 92vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: G 15s infinite;
}

@keyframes G {
  0%  {top: 8vh; left: 92vh;}
  40% {top: 8vh; left: 92vh;}
  50% {top: 8vh; left: 120vh;}
  60% {top: 8vh; left: 120vh;}
  70% {top: 8vh; left: 92vh;}
  100% {top: 8vh; left: 92vh;}
}

.R {
  position: absolute;
  top: 8vh;
  left: 97vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: R 15s infinite;
}

@keyframes R {
  0%  {top: 8vh; left: 97vh;}
  35% {top: 8vh; left: 97vh;}
  50% {top: 8vh; left: 125vh;}
  65% {top: 8vh; left: 125vh;}
  100% {top: 8vh; left: 97vh;}
}

.O {
  position: absolute;
  top: 8vh;
  left: 101vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: O 15s infinite;
}

@keyframes O {
  0%  {top: 8vh; left: 101vh;}
  35% {top: 8vh; left: 101vh;}
  50% {top: 8vh; left: 129vh;}
  65% {top: 8vh; left: 129vh;}
  100% {top: 8vh; left: 101vh;}
}

.U {
  position: absolute;
  top: 8vh;
  left: 106vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: U 15s infinite;
}

@keyframes U {
  0%  {top: 8vh; left: 106vh;}
  35% {top: 8vh; left: 106vh;}
  50% {top: 8vh; left: 134vh;}
  65% {top: 8vh; left: 134vh;}
  100% {top: 8vh; left: 106vh;}
}

.N {
  position: absolute;
  top: 8vh;
  left: 111vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: N 15s infinite;
}

@keyframes N {
  0%  {top: 8vh; left: 111vh;}
  35% {top: 8vh; left: 111vh;}
  50% {top: 8vh; left: 139vh;}
  65% {top: 8vh; left: 139vh;}
  100% {top: 8vh; left: 111vh;}
}

.D {
  position: absolute;
  top: 8vh;
  left: 114vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: D 15s infinite;
}

@keyframes D {
  0%  {top: 8vh; left: 114vh;}
  35% {top: 8vh; left: 114vh;}
  50% {top: 8vh; left: 142vh;}
  65% {top: 8vh; left: 142vh;}
  100% {top: 8vh; left: 114vh;}
}

}

@media (max-width: 600px) {
  .react-logo {
    position: absolute;
    width: 50.5vh;
    height: 17.5vh;  
    margin-top: 20vh;
  }
  .react-logo__circle {
    position: absolute;
    width: 10.32vh;
    height: 10.32vh;
    border-radius: 50%;
    background-color: #00c5e8;
    left: 16.7vh;
    top: 24.8vh;
  }

  .react-logo__ellipse {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 0.5vh;
    top: 19.6vh;
   
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
  }

  .react-logo__ellipse_2 {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 0.5vh;
    top: 19.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(60deg);
    animation: logomove1 5s infinite;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }

  .react-logo__ellipse_3 {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 0.5vh;
    top: 19.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(120deg);
    animation: logomove3 5s infinite;
    animation-timing-function: cubic-bezier(0.75, 0.75, 0.25, 0.25);
  }



@keyframes logomove1 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(90deg);}
  50%  {transform: rotate(180deg);}
  75%  {transform: rotate(270deg);}
  100% {transform: rotate(360deg);}
  
}

@keyframes logomove2 {
  0%, 25%   {transform: rotate(0deg);}
  50%  {transform: rotate(120deg);}
  75%  {transform: rotate(240deg);}
  100% {transform: rotate(360deg);}
}

@keyframes logomove3 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(-90deg);}
  50%  {transform: rotate(-180deg);}
  75%  {transform: rotate(-270deg);}
  100% {transform: rotate(-360deg);}
}

.message {
  opacity: 1;
  position: relative;
  top: 60vh;
  font-size: 2.3vh;
  text-align: center;
  left: -3vh;
}

.ball {
  opacity: 0;
}

.P {
  position: absolute;
  top: 16vh;
  left: 1vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.L {
  position: absolute;
  top: 16vh;
  left: 6vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.A {
  position: absolute;
  top: 16vh;
  left: 9vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.Y {
  position: absolute;
  top: 16vh;
  left: 13vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.G {
  position: absolute;
  top: 16vh;
  left: 16vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.R {
  position: absolute;
  top: 16vh;
  left: 21vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.O {
  position: absolute;
  top: 16vh;
  left: 25vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.U {
  position: absolute;
  top: 16vh;
  left: 30vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.N {
  position: absolute;
  top: 16vh;
  left: 35vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.D {
  position: absolute;
  top: 16vh;
  left: 38vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

}

@media screen and (min-width: 601px) and (max-width:1099px){
  .react-logo {
    position: absolute;
    width: 50.5vh;
    height: 17.5vh;  
    margin-top: 20vh;
  }
  .react-logo__circle {
    position: absolute;
    width: 10.32vh;
    height: 10.32vh;
    border-radius: 50%;
    background-color: #00c5e8;
    left: 27.7vh;
    top: 24.8vh;
  }

  .react-logo__ellipse {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 11.5vh;
    top: 19.6vh;
   
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
  }

  .react-logo__ellipse_2 {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 11.5vh;
    top: 19.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(60deg);
    animation: logomove1 5s infinite;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }

  .react-logo__ellipse_3 {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 11.5vh;
    top: 19.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(120deg);
    animation: logomove3 5s infinite;
    animation-timing-function: cubic-bezier(0.75, 0.75, 0.25, 0.25);
  }



@keyframes logomove1 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(90deg);}
  50%  {transform: rotate(180deg);}
  75%  {transform: rotate(270deg);}
  100% {transform: rotate(360deg);}
  
}

@keyframes logomove2 {
  0%, 25%   {transform: rotate(0deg);}
  50%  {transform: rotate(120deg);}
  75%  {transform: rotate(240deg);}
  100% {transform: rotate(360deg);}
}

@keyframes logomove3 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(-90deg);}
  50%  {transform: rotate(-180deg);}
  75%  {transform: rotate(-270deg);}
  100% {transform: rotate(-360deg);}
}

.message {
  opacity: 1;
  position: relative;
  top: 60vh;
  font-size: 2.6vh;
  text-align: center;
  left: 8vh;
}

.ball {
  opacity: 0;
}

.P {
  position: absolute;
  top: 16vh;
  left: 15vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.L {
  position: absolute;
  top: 16vh;
  left: 20vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.A {
  position: absolute;
  top: 16vh;
  left: 23vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.Y {
  position: absolute;
  top: 16vh;
  left: 27vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.G {
  position: absolute;
  top: 16vh;
  left: 30vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.R {
  position: absolute;
  top: 16vh;
  left: 35vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.O {
  position: absolute;
  top: 16vh;
  left: 39vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.U {
  position: absolute;
  top: 16vh;
  left: 44vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.N {
  position: absolute;
  top: 16vh;
  left: 49vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.D {
  position: absolute;
  top: 16vh;
  left: 52vh;
  font-family: Indie Flower;
  font-size: 8vh;
}
}

