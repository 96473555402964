.image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 0 10px; 
    grid-auto-rows: 10px;
}

.image-list img {
    width: 250px;
    grid-row-end: span 2;
}

@media screen and (max-width: 575px){
    .image-list{
    margin-left: 5vh;
    }
}

@media screen and (min-width: 576px) and (max-width:800px){
    .image-list{
    margin-left: 6vh;
    }
}
