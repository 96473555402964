.rec.rec-dot_active{
  background-color: #00c5e8;
  box-shadow: 0 0 1px 3px #008096;
}
.rec.rec-dot:hover{
  box-shadow: 0 0 1px 3px #008096;
}
.rec.rec-dot:focus{
  box-shadow: 0 0 1px 3px #008096;
}
.rec.rec-arrow:focus:enabled{
  background-color:  #00c5e8;
  color: white;
  outline: none;
}
.rec.rec-arrow:hover:enabled{
  background-color: #00c5e8;
}
