

img {
  width: 20%;
  z-index: 10;
}

@media (max-width: 600px) {
  img {
    width: 50%
  }
}

@media (max-width: 800px) {
  img {
    width: 50%
  }
}
