 
.flex-container-simon {
  margin: 10.32vh auto;
  display: flex;
  width: 51.6vh;
  height: 51.6vh;
  background: #222;
  flex-wrap: wrap;
  margin-top: 25vh;
  }
  
.colors-simon {
  margin: auto;
  height: 25.8vh;
  width: 25.8vh;
}
.t-l {
  background: green;
  border-top-left-radius : 100%;
  border: 0.5vh solid black;
}
.t-r {
  background: red;  
  border-top-right-radius : 100%;
  border: 0.5vh solid black;
}
.b-l {
  background: yellow;
  border-bottom-left-radius : 100%;
  border: 0.5vh solid black;
}
.b-r {
  background: blue;
  border-bottom-right-radius : 100%;
  border: 0.5vh solid black;
}


.center-simon {
  position: absolute;
  border-radius: 100%;
  margin: 13.5vh 0 0 15.5vh;
  margin-left: 14vh;
  background: white;
  width: 23vh;
  height: 23vh;
  border: 12px solid black;
  text-align: center;
}

.anim {
  opacity: 0.2;
}
 


.count-simon {
  font-family: 'VT323', monospace;
  color : #DC0D29;
  font-size : 2.7vh;
  width : 4.16vh;
  background-color: #32050C;
  border : 4px solid #222;
  border-radius : 10px ;
  margin-left: 2.07vh;
  margin-right: 5px;
  margin-top: -2px;
}
.round-btn-simon {
  width : 20px;
  height : 20px;
  position: relative;
  background-color : yellow;
  border-radius : 100%;
  border : 4px solid #444;
  box-shadow : 0px 2px 3px #222;
  margin:auto;
}

.round-btn-simon:active {
  box-shadow: 0 1px 1px #292929;
  top: -4px;
}

.led-simon {
  width: 16px;
  height: 16px;
  background-color: #32050C;
  border-radius : 100%;
  border: 2px solid #222;
 
}

.led-on {
  background-color: #DC0D29;
}


.alert {
  text-align: center
}

#simon-title {
  font-size:3.5vh;
  margin-top:5vh;
  font-family: monospace;
}

@media (max-width: 330px) {
  .flex-container-simon {
    margin: 10.32vh auto;
    display: flex;
    width: 50vh;
    height: 50vh;
    background: #222;
    flex-wrap: wrap;
    margin-top: 25vh;
    margin-left: 3vh;
    }
    .colors-simon {
      margin: auto;
      height: 25vh;
      width: 25vh;
    }
    .center-simon {
      position: absolute;
      border-radius: 100%;
      margin: 12.5vh 0 0 12.5vh;
      margin-left: 12vh;
      background: white;
      width: 26vh;
      height: 26vh;
      border: 12px solid black;
      text-align: center;
    }
    #simon-title {
      font-size: 3.3vh;
      margin-top: 5vh;
    }
    .round-btn-simon {
      width: 10px;
      height: 15px;
      border: 3px solid #222;
    }
    .led-simon {
      margin-left: 2vh;
      position: absolute;
      top: 9vh;
    }
    
    #strict-text-simon {
      margin-left: 5vh;
    }

}

@media (min-width: 750px) {
  #restart-button-simon {
    margin-left: -7vh;
  }
}

