@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Texturina:wght@200;500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pangolin&display=swap);


img {
  width: 20%;
  z-index: 10;
}

@media (max-width: 600px) {
  img {
    width: 50%
  }
}

@media (max-width: 800px) {
  img {
    width: 50%
  }
}

.rec.rec-dot_active{
  background-color: #00c5e8;
  box-shadow: 0 0 1px 3px #008096;
}
.rec.rec-dot:hover{
  box-shadow: 0 0 1px 3px #008096;
}
.rec.rec-dot:focus{
  box-shadow: 0 0 1px 3px #008096;
}
.rec.rec-arrow:focus:enabled{
  background-color:  #00c5e8;
  color: white;
  outline: none;
}
.rec.rec-arrow:hover:enabled{
  background-color: #00c5e8;
}

.video-item {
    display: flex !important;
    align-items: center !important;
    cursor: pointer;

}

.video-item.item img {
    max-width: 180px;
}
.image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 0 10px; 
    grid-auto-rows: 10px;
}

.image-list img {
    width: 250px;
    grid-row-end: span 2;
}

@media screen and (max-width: 575px){
    .image-list{
    margin-left: 5vh;
    }
}

@media screen and (min-width: 576px) and (max-width:800px){
    .image-list{
    margin-left: 6vh;
    }
}

.calculator-wrapper {
  align-items: center;
  margin-top: 15vh;
  justify-content: center;
  display: flex;
}

.calculator {
  border: 0.2vh solid #5e5858;
  padding: 0.5vh;
  background: black;
  width: 34.5vh;
  height: 40.7vh;
  position: relative;
}

.formulaScreen {
  min-height: 2vh;
  font-family: digital;
  font-size: 2vh;
  color: rgb(247, 126, 12);
  text-align: right;
  vertical-align: text-top;
  line-height: 2vh;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.outputScreen {
  font-size: 3vh;
  font-family: digital;
  color: white;
  text-align: right;
  line-height: 3.6vh;
}

.calculator-buttons {
 margin-top: 0%;
}
.calculator-button {
  position: relative;
  height: 6.7vh;
  width: 8.25vh;
  color: white;
  outline: 0.1vh solid black;
  background: rgb(77, 77, 77);
  font-family: monospace;
  font-size: 2vh;
  cursor: default;
}

.calculator-button:hover {
  color: black;
  outline: 0.05em solid grey;
  z-index: 0;
}
.calculator-button:focus {
  outline: 0px;
  color: white;
}

.jumbo {
  position: relative;
  height: 6.7vh;
  width: 16.5vh;
  color: white;
  outline: 0.1vh solid black;
  background: rgb(77, 77, 77);
  font-family: monospace;
  font-size: 2vh;
  cursor: default;
}
.jumbo:hover{
  color: black;
  outline: 0.05em solid grey;
  z-index: 0;
}
.jumbo:focus {
  outline: 0px;
  color: white;
}

.equalbtn {
  background: #004466; 
  position: absolute; 
  height: 13.42vh; 
  bottom: 0.8vh;
  width: 8.25vh;
  color: white;
  font-family: monospace;
  font-size: 2vh;
  cursor: default;
}
.equalbtn:hover{
  color: black;
  outline: 0.05em solid grey;
  z-index: 0;
}
.equalbtn:focus{
  outline: 0px;
  color: white;
}


@media (min-width: 1100px) {



  .ball {
    width: 10.3vh;
    height: 10.3vh;
    background:#ffff05;
    position: absolute;
    border-radius: 50%;
    animation: mymove1 5s infinite;
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5);
  }

  .ball:hover {
    animation: reverse;
  }
  
  .mySquare {
    width: 8.3vh;
    height: 8.3vh;
    background:#00c5e8;
    position: absolute;
    animation: mymove2 15s infinite;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }
  
  
  @keyframes mymove1 {
    0%   {top: 7.2vh; left: 25.8vh; opacity: 0.75;}
    25%  {top: 48.6vh; left: 25.8vh; border-radius: 0%; opacity: 1;}
    50%  {top: 89.5vh; left: 25.8vh; border-radius: 50%; opacity: 0.75;}
    75%  {top: 48.6vh; left: 25.8vh; border-radius: 0%; opacity: 1;}
    100% {top: 7.2vh; left: 25.8vh; opacity: 0.75;}
  }
  
  @keyframes mymove2 {
    0%   {top: 90vh; left: 93.4vh; transform: rotate(45deg); background-color: purple;}
    25%  {top: 43.9vh; left: 188.4vh; transform: rotate(135deg); background-color: yellow;}
    50%  {top: 8.8vh; left: 93.4vh; transform: rotate(225deg); background-color: green;}
    75%  {top: 43.9vh; left: 1vh; transform: rotate(135deg); background-color: red;}
    100% {top: 90vh; left: 93.4vh; transform: rotate(225deg);background-color: purple;}
  }

  


  .react-logo {
    position: absolute;
    width: 57.5vh;
    height: 23.5vh;  
    margin-top: 20vh;
    margin-left: 40vh;
  }
  .react-logo__circle {
    position: absolute;
    width: 10.32vh;
    height: 10.32vh;
    border-radius: 50%;
    background-color: #00c5e8;
    left: 21.7vh;
    top: 24.8vh;
  }

  .react-logo__ellipse {
    position: absolute;
    width: 53vh;
    height: 25vh;
    left: 0;
    top: 16.6vh;
   
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
  }

  .react-logo__ellipse_2 {
    position: absolute;
    width: 53vh;
    height: 25vh;
    left: 0;
    top: 16.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(60deg);
    animation: logomove1 5s infinite;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }

  .react-logo__ellipse_3 {
    position: absolute;
    width: 53vh;
    height: 25vh;
    left: 0;
    top: 16.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(120deg);
    animation: logomove3 5s infinite;
    animation-timing-function: cubic-bezier(0.75, 0.75, 0.25, 0.25);
  }



@keyframes logomove1 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(90deg);}
  50%  {transform: rotate(180deg);}
  75%  {transform: rotate(270deg);}
  100% {transform: rotate(360deg);}
  
}

@keyframes logomove2 {
  0%, 25%   {transform: rotate(0deg);}
  50%  {transform: rotate(120deg);}
  75%  {transform: rotate(240deg);}
  100% {transform: rotate(360deg);}
}

@keyframes logomove3 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(-90deg);}
  50%  {transform: rotate(-180deg);}
  75%  {transform: rotate(-270deg);}
  100% {transform: rotate(-360deg);}
}

.message {
  opacity: 0;
}



.P {
  position: absolute;
  top: 8vh;
  left: 76vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: P 15s infinite;
}

@keyframes P {
  0%  {top: 8vh; left: 76vh;}
  35% {top: 8vh; left: 76vh;}
  50% {top: 8vh; left: 60vh;}
  65% {top: 8vh; left: 60vh;}
  100% {top: 8vh; left: 76vh;}
}

.L {
  position: absolute;
  top: 8vh;
  left: 81vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: L 15s infinite;
  animation-timing-function: cubic-bezier(0.25, 0.75, 0.75, 0.25)
}

@keyframes L {
  0% {top: 8vh; left: 81vh; transform: rotate(0deg);}
  5% {transform: rotate(45deg);}
  10% {transform: rotate(0deg);}
  15% {transform: rotate(-45deg);}
  20% {transform: rotate(0deg);}
  25% {transform: rotate(45deg);}
  30% {transform: rotate(0deg);}
  35% {transform: rotate(-45deg);}
  40% {top: 8vh; left: 81vh; transform: rotate(0deg);}
  45% {top: 23vh; left: 81vh; transform: rotate(45deg);}
  50% {transform: rotate(0deg);}
  55% {transform: rotate(-45deg);}
  60% {transform: rotate(0deg);}
  65% {transform: rotate(45deg);}
  70% {top: 23vh; left: 81vh; transform: rotate(0deg);}
  75% {transform: rotate(-45deg);}
  80% {transform: rotate(0deg);}
  85% {transform: rotate(45deg);}
  90% {top: 8vh; left: 81vh; transform: rotate(0deg);}
  95% {transform: rotate(-45deg);}
  100% {top: 8vh; left: 81vh; transform: rotate(0deg);}
}

.A {
  position: absolute;
  top: 8vh;
  left: 84vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: A 15s infinite;
}

@keyframes A {
  0%  {top: 8vh; left: 84vh;}
  35% {top: 8vh; left: 84vh;}
  50% {top: 8vh; left: 68vh;}
  65% {top: 8vh; left: 68vh;}
  100% {top: 8vh; left: 84vh;}
}

.Y {
  position: absolute;
  top: 8vh;
  left: 89vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: Y 15s infinite;
}

@keyframes Y {
  0%  {top: 8vh; left: 89vh;}
  35% {top: 8vh; left: 89vh;}
  50% {top: 8vh; left: 73vh;}
  65% {top: 8vh; left: 73vh;}
  100% {top: 8vh; left: 89vh;}
}

.G {
  position: absolute;
  top: 8vh;
  left: 92vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: G 15s infinite;
}

@keyframes G {
  0%  {top: 8vh; left: 92vh;}
  40% {top: 8vh; left: 92vh;}
  50% {top: 8vh; left: 120vh;}
  60% {top: 8vh; left: 120vh;}
  70% {top: 8vh; left: 92vh;}
  100% {top: 8vh; left: 92vh;}
}

.R {
  position: absolute;
  top: 8vh;
  left: 97vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: R 15s infinite;
}

@keyframes R {
  0%  {top: 8vh; left: 97vh;}
  35% {top: 8vh; left: 97vh;}
  50% {top: 8vh; left: 125vh;}
  65% {top: 8vh; left: 125vh;}
  100% {top: 8vh; left: 97vh;}
}

.O {
  position: absolute;
  top: 8vh;
  left: 101vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: O 15s infinite;
}

@keyframes O {
  0%  {top: 8vh; left: 101vh;}
  35% {top: 8vh; left: 101vh;}
  50% {top: 8vh; left: 129vh;}
  65% {top: 8vh; left: 129vh;}
  100% {top: 8vh; left: 101vh;}
}

.U {
  position: absolute;
  top: 8vh;
  left: 106vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: U 15s infinite;
}

@keyframes U {
  0%  {top: 8vh; left: 106vh;}
  35% {top: 8vh; left: 106vh;}
  50% {top: 8vh; left: 134vh;}
  65% {top: 8vh; left: 134vh;}
  100% {top: 8vh; left: 106vh;}
}

.N {
  position: absolute;
  top: 8vh;
  left: 111vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: N 15s infinite;
}

@keyframes N {
  0%  {top: 8vh; left: 111vh;}
  35% {top: 8vh; left: 111vh;}
  50% {top: 8vh; left: 139vh;}
  65% {top: 8vh; left: 139vh;}
  100% {top: 8vh; left: 111vh;}
}

.D {
  position: absolute;
  top: 8vh;
  left: 114vh;
  font-family: Indie Flower;
  font-size: 8vh;
  animation: D 15s infinite;
}

@keyframes D {
  0%  {top: 8vh; left: 114vh;}
  35% {top: 8vh; left: 114vh;}
  50% {top: 8vh; left: 142vh;}
  65% {top: 8vh; left: 142vh;}
  100% {top: 8vh; left: 114vh;}
}

}

@media (max-width: 600px) {
  .react-logo {
    position: absolute;
    width: 50.5vh;
    height: 17.5vh;  
    margin-top: 20vh;
  }
  .react-logo__circle {
    position: absolute;
    width: 10.32vh;
    height: 10.32vh;
    border-radius: 50%;
    background-color: #00c5e8;
    left: 16.7vh;
    top: 24.8vh;
  }

  .react-logo__ellipse {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 0.5vh;
    top: 19.6vh;
   
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
  }

  .react-logo__ellipse_2 {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 0.5vh;
    top: 19.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(60deg);
    animation: logomove1 5s infinite;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }

  .react-logo__ellipse_3 {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 0.5vh;
    top: 19.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(120deg);
    animation: logomove3 5s infinite;
    animation-timing-function: cubic-bezier(0.75, 0.75, 0.25, 0.25);
  }



@keyframes logomove1 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(90deg);}
  50%  {transform: rotate(180deg);}
  75%  {transform: rotate(270deg);}
  100% {transform: rotate(360deg);}
  
}

@keyframes logomove2 {
  0%, 25%   {transform: rotate(0deg);}
  50%  {transform: rotate(120deg);}
  75%  {transform: rotate(240deg);}
  100% {transform: rotate(360deg);}
}

@keyframes logomove3 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(-90deg);}
  50%  {transform: rotate(-180deg);}
  75%  {transform: rotate(-270deg);}
  100% {transform: rotate(-360deg);}
}

.message {
  opacity: 1;
  position: relative;
  top: 60vh;
  font-size: 2.3vh;
  text-align: center;
  left: -3vh;
}

.ball {
  opacity: 0;
}

.P {
  position: absolute;
  top: 16vh;
  left: 1vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.L {
  position: absolute;
  top: 16vh;
  left: 6vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.A {
  position: absolute;
  top: 16vh;
  left: 9vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.Y {
  position: absolute;
  top: 16vh;
  left: 13vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.G {
  position: absolute;
  top: 16vh;
  left: 16vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.R {
  position: absolute;
  top: 16vh;
  left: 21vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.O {
  position: absolute;
  top: 16vh;
  left: 25vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.U {
  position: absolute;
  top: 16vh;
  left: 30vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.N {
  position: absolute;
  top: 16vh;
  left: 35vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.D {
  position: absolute;
  top: 16vh;
  left: 38vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

}

@media screen and (min-width: 601px) and (max-width:1099px){
  .react-logo {
    position: absolute;
    width: 50.5vh;
    height: 17.5vh;  
    margin-top: 20vh;
  }
  .react-logo__circle {
    position: absolute;
    width: 10.32vh;
    height: 10.32vh;
    border-radius: 50%;
    background-color: #00c5e8;
    left: 27.7vh;
    top: 24.8vh;
  }

  .react-logo__ellipse {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 11.5vh;
    top: 19.6vh;
   
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
  }

  .react-logo__ellipse_2 {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 11.5vh;
    top: 19.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(60deg);
    animation: logomove1 5s infinite;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }

  .react-logo__ellipse_3 {
    position: absolute;
    width: 42vh;
    height: 20vh;
    left: 11.5vh;
    top: 19.6vh;
    border: 2.4vh solid #00c5e8;
    border-radius: 50%;
    border-color: #00c5e8;
    transform: rotate(120deg);
    animation: logomove3 5s infinite;
    animation-timing-function: cubic-bezier(0.75, 0.75, 0.25, 0.25);
  }



@keyframes logomove1 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(90deg);}
  50%  {transform: rotate(180deg);}
  75%  {transform: rotate(270deg);}
  100% {transform: rotate(360deg);}
  
}

@keyframes logomove2 {
  0%, 25%   {transform: rotate(0deg);}
  50%  {transform: rotate(120deg);}
  75%  {transform: rotate(240deg);}
  100% {transform: rotate(360deg);}
}

@keyframes logomove3 {
  0%   {transform: rotate(0deg);}
  25%  {transform: rotate(-90deg);}
  50%  {transform: rotate(-180deg);}
  75%  {transform: rotate(-270deg);}
  100% {transform: rotate(-360deg);}
}

.message {
  opacity: 1;
  position: relative;
  top: 60vh;
  font-size: 2.6vh;
  text-align: center;
  left: 8vh;
}

.ball {
  opacity: 0;
}

.P {
  position: absolute;
  top: 16vh;
  left: 15vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.L {
  position: absolute;
  top: 16vh;
  left: 20vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.A {
  position: absolute;
  top: 16vh;
  left: 23vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.Y {
  position: absolute;
  top: 16vh;
  left: 27vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.G {
  position: absolute;
  top: 16vh;
  left: 30vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.R {
  position: absolute;
  top: 16vh;
  left: 35vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.O {
  position: absolute;
  top: 16vh;
  left: 39vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.U {
  position: absolute;
  top: 16vh;
  left: 44vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.N {
  position: absolute;
  top: 16vh;
  left: 49vh;
  font-family: Indie Flower;
  font-size: 8vh;
}

.D {
  position: absolute;
  top: 16vh;
  left: 52vh;
  font-family: Indie Flower;
  font-size: 8vh;
}
}


.controls-container {
    float: right;
    width: 24.8vh;
    height: 28.1vh;
    margin: 40px 20px 0 10px;
    vertical-align: top;
    font-family: Texturina;
    } 
  .controls-container > .control {
      width: 10.32vh;
      margin: auto;
    }
    .controls-container > .control > p {
      margin-bottom: 0;
    }
  .controls-container > #display {
      width: 20.64vh;
      background-color: gray;
      margin: 1.55vh auto;
      padding: 1.55vh;
      box-sizing: border-box;
    }
    .select {
      margin: auto;
      border: 0.1vh solid black;
      width: 5.16vh;
      height: 2.3vh;
      padding: 0.1vh;
      background-color: black;
    }

.inner-select {
  width: 2.4vh;
  height: 2vh;
  background: gold;
  border: 0.1vh solid black;
  box-sizing: border-box;
  cursor: pointer;
}

#display {
  width: 20.6vh;
  background-color: gray;
  margin: 1.55vh auto;
  padding: 1.55vh;
  box-sizing: border-box;
}


.inner-container {
  outline: 0.5vh solid gold;
  position: relative;
  width: 68.1vh;
  text-align: center;
  background-color: lighten(grey, 20%);
}
  .pad-bank {
    width: 34.3vh;
    height: 28.01vh;
    display: inline-block;
    margin: 2.1vh;
    margin-top: 1vh;
}
    .drum-pad {
      position: relative;
      float: left;
      width: 10.32vh;
      height: 8.26vh;
      margin-right: 1.03vh;
      border-radius: 0.52vh;
      padding-top: 3.62vh;
      box-sizing: border-box;
      cursor: pointer;
    }

    
    @media screen and (max-width: 650px) {
      .inner-container {
        transform: scale(0.8);
        height: 80vh;
        margin-top: -15vh;
      }
      .pad-bank {
        margin-top: 4vh;
        margin-right: 6vh;
        transform: scale(1.1);
        }
        .controls-container {
          margin-right: 17vh;
          margin-top: 10vh;
        }
        .control {
          transform: scale(1.4);
        }
        .inner-select {
          margin-bottom: 2vh ;
        }
        .select {
          margin-bottom: 4vh;
        }
    } 
    @media screen and (max-width: 575px) {
      .controls-container{
        margin-right: 11vh;
      }
    }
    







.quote-box {
  border-radius: 0.3vh;
  border: 1vh solid rgb(2, 161, 235);
  position: relative;
  margin:auto auto auto auto;
  width: 46.4vh;
  padding: 4vh 5vh;
  display: table;
  background-color: #87edff;
}

.body {
  background-color:white;
  color: black;
  font-family: 'Pangolin', cursive;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 97.8vh;
}

.title {
  position: absolute;
  top: 20vh;
  font-family: Pangolin;
  
}

@media screen and (max-width: 650px) {
  .title {
    top: 15vh;
    left: 6vh;
  }
  .quote-box{
    transform: scale(0.9);
  }

}

@media screen and (max-width: 575px){
  .title {
    top: 15vh;
    left: 4vh;
  }
  .quote-box{
    transform: scale(0.75);
  }
}

@media screen and (min-width: 651px) and (max-width:1025px) {
  .title {
    top: 15vh;
    left: 14vh;
  }

}
.quote-text {
 
  text-align: center;
  width: 46.4vh;
  height: auto;
  clear: both;
  font-weight: 500;
  font-size: 1.75em;  
}

.quote-author {
  width: 46.4vh;
  height: auto;
  clear: both;
  padding-top: 2.07vh;
  font-size: 1em;
  text-align: right;
  font-size: 1.8vh;
}
.buttons {
  width: 46.4vh;
  margin: auto;
  display: block;
}
.button {
  height: 6.5vh;
  width: 8vh;
  border: none;
  border-radius: 0.3vh;
  color: black;
  background-color: rgb(2, 161, 235);
  outline: none;
  font-size: 0.85em;
  padding: 0.8vh 1.86vh 0.8vh 1.86vh;
  margin-top: 3.1vh;
  opacity: 1;
  cursor: pointer;
 
}
.button:hover {
  opacity: 0.9;
}
.tweet-button{
  height: 3.9vh;
  border: none;
  border-radius: 0.3vh;
  outline: none;
  font-size: 0.85em;
  padding: 0.8vh 1.86vh 0.6vh 1.86vh;
  margin-top: 3.1vh;
  opacity: 1;
  cursor: pointer;
  background-color: #87edff;
}
#tweet-quote {
  float: left;
  padding: 0px;
  padding-top: 0.8vh;
  text-align: center;
  font-size: 1.8em;
  margin-right: 0.6vh;
  height: 3.1vh;
  width: 4.13vh;
  color: rgb(2, 161, 235)

}
#new-quote {
  float: right;
  font-size: 1.8vh;
  text-align: center;
  font-family: Pangolin;
  color: black;
  outline: none;
}
 
.flex-container-simon {
  margin: 10.32vh auto;
  display: flex;
  width: 51.6vh;
  height: 51.6vh;
  background: #222;
  flex-wrap: wrap;
  margin-top: 25vh;
  }
  
.colors-simon {
  margin: auto;
  height: 25.8vh;
  width: 25.8vh;
}
.t-l {
  background: green;
  border-top-left-radius : 100%;
  border: 0.5vh solid black;
}
.t-r {
  background: red;  
  border-top-right-radius : 100%;
  border: 0.5vh solid black;
}
.b-l {
  background: yellow;
  border-bottom-left-radius : 100%;
  border: 0.5vh solid black;
}
.b-r {
  background: blue;
  border-bottom-right-radius : 100%;
  border: 0.5vh solid black;
}


.center-simon {
  position: absolute;
  border-radius: 100%;
  margin: 13.5vh 0 0 15.5vh;
  margin-left: 14vh;
  background: white;
  width: 23vh;
  height: 23vh;
  border: 12px solid black;
  text-align: center;
}

.anim {
  opacity: 0.2;
}
 


.count-simon {
  font-family: 'VT323', monospace;
  color : #DC0D29;
  font-size : 2.7vh;
  width : 4.16vh;
  background-color: #32050C;
  border : 4px solid #222;
  border-radius : 10px ;
  margin-left: 2.07vh;
  margin-right: 5px;
  margin-top: -2px;
}
.round-btn-simon {
  width : 20px;
  height : 20px;
  position: relative;
  background-color : yellow;
  border-radius : 100%;
  border : 4px solid #444;
  box-shadow : 0px 2px 3px #222;
  margin:auto;
}

.round-btn-simon:active {
  box-shadow: 0 1px 1px #292929;
  top: -4px;
}

.led-simon {
  width: 16px;
  height: 16px;
  background-color: #32050C;
  border-radius : 100%;
  border: 2px solid #222;
 
}

.led-on {
  background-color: #DC0D29;
}


.alert {
  text-align: center
}

#simon-title {
  font-size:3.5vh;
  margin-top:5vh;
  font-family: monospace;
}

@media (max-width: 330px) {
  .flex-container-simon {
    margin: 10.32vh auto;
    display: flex;
    width: 50vh;
    height: 50vh;
    background: #222;
    flex-wrap: wrap;
    margin-top: 25vh;
    margin-left: 3vh;
    }
    .colors-simon {
      margin: auto;
      height: 25vh;
      width: 25vh;
    }
    .center-simon {
      position: absolute;
      border-radius: 100%;
      margin: 12.5vh 0 0 12.5vh;
      margin-left: 12vh;
      background: white;
      width: 26vh;
      height: 26vh;
      border: 12px solid black;
      text-align: center;
    }
    #simon-title {
      font-size: 3.3vh;
      margin-top: 5vh;
    }
    .round-btn-simon {
      width: 10px;
      height: 15px;
      border: 3px solid #222;
    }
    .led-simon {
      margin-left: 2vh;
      position: absolute;
      top: 9vh;
    }
    
    #strict-text-simon {
      margin-left: 5vh;
    }

}

@media (min-width: 750px) {
  #restart-button-simon {
    margin-left: -7vh;
  }
}


