@import 'https://fonts.googleapis.com/css?family=Share+Tech+Mono';

.calculator-wrapper {
  align-items: center;
  margin-top: 15vh;
  justify-content: center;
  display: flex;
}

.calculator {
  border: 0.2vh solid #5e5858;
  padding: 0.5vh;
  background: black;
  width: 34.5vh;
  height: 40.7vh;
  position: relative;
}

.formulaScreen {
  min-height: 2vh;
  font-family: digital;
  font-size: 2vh;
  color: rgb(247, 126, 12);
  text-align: right;
  vertical-align: text-top;
  line-height: 2vh;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.outputScreen {
  font-size: 3vh;
  font-family: digital;
  color: white;
  text-align: right;
  line-height: 3.6vh;
}

.calculator-buttons {
 margin-top: 0%;
}
.calculator-button {
  position: relative;
  height: 6.7vh;
  width: 8.25vh;
  color: white;
  outline: 0.1vh solid black;
  background: rgb(77, 77, 77);
  font-family: monospace;
  font-size: 2vh;
  cursor: default;
}

.calculator-button:hover {
  color: black;
  outline: 0.05em solid grey;
  z-index: 0;
}
.calculator-button:focus {
  outline: 0px;
  color: white;
}

.jumbo {
  position: relative;
  height: 6.7vh;
  width: 16.5vh;
  color: white;
  outline: 0.1vh solid black;
  background: rgb(77, 77, 77);
  font-family: monospace;
  font-size: 2vh;
  cursor: default;
}
.jumbo:hover{
  color: black;
  outline: 0.05em solid grey;
  z-index: 0;
}
.jumbo:focus {
  outline: 0px;
  color: white;
}

.equalbtn {
  background: #004466; 
  position: absolute; 
  height: 13.42vh; 
  bottom: 0.8vh;
  width: 8.25vh;
  color: white;
  font-family: monospace;
  font-size: 2vh;
  cursor: default;
}
.equalbtn:hover{
  color: black;
  outline: 0.05em solid grey;
  z-index: 0;
}
.equalbtn:focus{
  outline: 0px;
  color: white;
}

